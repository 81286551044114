/* eslint-disable no-unused-vars */
import { IMarkerPictureFields } from './address.types';
import { DefaultPageParams } from './table.base.types';
import { IResolve } from './communal.types';

export enum DelegateType {
  none, // 沒有進行派單
  send, // 发出
  receive, // 接收
}

interface FilterField {
  BusinessType?: number[];
  DeliveryDateStart?: string;
  DeliveryDateEnd?: string;
  ExternalOrderId?: string;
  SalesOrderNumber?: string;
  SerialNumber?: string;
  OrderStatus?: number[];
  OrderTimeOutStatus?: number;
  DataOrigin?: number;
  PermissionId?: string;
  PickUpContactName?: string;
  PickUpStop?: string;
  DeliveryContractInfo?: string;
  DriverInfo?: string;
  StoreShortName?: string;
  OrderEvaluateStatus: number;
  DelegateType: DelegateType
}

// 筛选过滤
export type IFilterFields = DefaultPageParams & Partial<FilterField>;

export interface IOrderEvaluateContent {
  Name: string;
  Star: number;
  Labels: string[];
}

export interface IOrderEvaluationItem {
  OrderId: string;
  EvaluateAuditId: string;
  EvaluateUserId: string;
  Evaluator: string;
  EvaluationCustomerId: string;
  EvaluationCustomer: string;
  CustomerSerialNumber: string;
  EvaluationTime: string;
  Remark: string;
  EvaluateType: number;
  AuditStatus: number;
  ImageUrls: string[];
  OrderEvaluateContent: IOrderEvaluateContent[];
}

// 修改運單字段
export interface IUpdateOrderFormData {
  DeliveryDate: string; // 送貨日期
  OrderId: string; // 訂單Id
  UpdateOrderType: string | null; // 更新訂單類型
  DeliveryTime: string; // 送货时间
  SerialNumber: string; // 订单号码
  PickUpAddress: string; // 取貨點地址
  PickUpContactName: string; // 取货联系人姓名
  PickUpAddressLatLng: string; // 取货地址经纬度
  PickUpContractPhoneNumber: string; // 取货联系人电话号码
  DeliveryAddress: string; // 送货地址
  DeliveryContractName: string; // 送货联系人姓名
  DeliveryAddressLatLng: string; // 送货地址经纬度
  DeliveryContractPhoneNumber: string; // 送货联系人电话
  PickUpTimeScope: string; // 取货时间范围
  DeliveryTimeScope: string; // 送货时间范围
  CommodityNameAndQuantity: string; // 商品名称和数量
  Amount: number; // 订单金额
  CashOnDelivery: boolean; // 是否货到付款
  ReceiptAmount: number; // 收款金额
  CommodityWeight: number; // 货品重量
  PackingQuantity: number; // 包装数量
  PackageSize: string; // 包装尺寸
  MerchType: number; // 業務類型
  OrderSpace: string; // 所属排单区域
  DeliverySpace: string; // 所属配送区域
  EstimatedPickUpLength: number; // 预计取货停留时长
  EstimatedDeliveryLength: number; // 预计落货停留时长
  DeliveryMapType?: number; // 送货点地图类型
  PickUpMapType?: number; // 取货点地图类型
  StartDeliveryTime: string; // 开始送货时间
  EndDeliveryTime: string; // 结束送货时间
  Remark: string; // 備註
}

// 列表
export type ITableListFields =  IUpdateOrderFormData & {
  ActualDeliveryTime: string;
  BusinessType: number;
  DeliveryAddress: string;
  OrderStatus: number;
  DataOrigin: number;
  Remark: string;
  DeliverDate: string;
  DriverName: string;
  DriverPhoneNumber: string;
  DeliveryPointSerialNumber: string;
  EstimatedDeliveryTime: string;
  SalesOrderNumber: string;
  PickupPointSerialNumber: string;
  RequiredDeliveryTime: Array<{
    End: string
    Start: string
  }>;
  OrderTimeOutStatus: number;
  ReceiptAmount: number;
  MarkerPictures?: IMarkerPictureFields[];
  StartDeliveryTime: string;   // 开始送货时间
  EndDeliveryTime: string;     // 结束送货时间
  ActualSinglePointUsingDistance: number; // 實際單站里程
  ActualSinglePointUsingTime: number; // 實際單站耗時
  EstimatedSinglePointUsingDistance: number; // 預計單站里程
  EstimatedSinglePointUsingTime: number; // 預計單站耗時
  CustomerSerialNumber: string;
  OrderEvaluateList: IOrderEvaluationItem[];
  EstimatedStationStayTime: number // 預計停留時間
  ErrorImages: string[] | null
  ErrorReason: string | null
  CommodityVolume: number; // 货品体积
  ShipmentQuantity: number // 出货数量
  Tips: number // 小费
  Tax: number // 税
  OrderQuantity: number // 下单数量
  OrderType: string // 送貨 | 退货
  DelegateType: DelegateType
  AgentOrganizationId: string // 接/派单公司id
  AgentOrganizationName: string // 接/派单公司id
}

export interface IGetOrderDataOriginData {
  Name: string;
  Value: number;
}
export type IGetOrderDataOriginIResolve = IResolve<IGetOrderDataOriginData[]>;

export interface IGetOrderDataOriginParams {
}

export type IUpdateDeliveryDate = Partial<IUpdateOrderFormData> & {};

export type IUpdateDeliveryTime = Partial<IUpdateOrderFormData> & {};

export interface IOrderItem {
  OrderId: string;
  SalesOrderNumber: string;
  MaterialNumber: string;
  MaterialDescription: string;
  SalesUnit: string;
  OrderQuantity: number;
  DeliveryQuantity: number;
  DocCondition: string;
  ItemCode: string;
  ConditionItemNumber: string;
  ConditionType: string;
  RateConditionAmountOrPercentage: number;
  Amount: number;
  ProductWeight: number;
  TotalWeight: number;
  ProductVolume: number;
  TotalVolume: number;
  RemarkCn: string;
  RemarkEn: string;
}

interface IOrderAgentOrganizationDto {
  AgentOrganizationName: string
  AgentOrganizationId: string
}
export type GetOrderAgentOrganizationsResolve = IResolve<IOrderAgentOrganizationDto[]>;

export interface IDelegateOrderParams {
  AgentOrganizationId: string
  OrderIdList: string[]
}
